<template>
  <div class="calendar" @click.prevent="provinceFlag=false;industryFlag=false">
    <div class="calendar-header"></div>
    <div class="calendar-main">
      <div class="calendar-search">
        <div class="search-box">
          <span class="search-tip">搜索公司</span>
          <!-- <input class="search-name" v-model="searchName" type="text" placeholder="请输入公司名称或代码" /> -->
          <company-search
            v-model="searchName"
            placeholder="请输入公司名称或代码"
            @itemClick="item => calendarDeatil(item.id, item.stockCode)"
          />
          <p v-show="isEmpty" class="empty-tip">请输入筛选条件</p>
        </div>
        <div class="search-box" style="margin-left:82px">
          <span class="search-tip">省份</span>
          <Select :selectArr="areaList" :modelValue="province" :selectWidth="234" :listFlag="provinceFlag" @selectBack="provinceBack" @listFlagFn="provinceFlagFn"/>
        </div>
        <div class="search-box" style="margin-left:74px">
          <span class="search-tip">所属行业</span>
          <Select :selectArr="industryList" :modelValue="industry" :selectWidth="189" :listFlag="industryFlag" @selectBack="industryBack" @listFlagFn="industryFlagFn"/>
        </div>
        <button class="search-btn" @click="searchCompany">搜索</button>
      </div>
      <div class="calendar-list" v-if="listArr&&listArr.length>0">
        <div class="each-list" v-for="item in listArr" :key="item.id" @click="calendarDeatil(item.id,item.stockCode)">
          <div class="simple-name">
            <img class="company-logo" :src="item.logo" alt="" @error="item.error = true" v-if="!item.error&&item.logo">
            <img class="company-logo" src="~@/assets/img/person/company.svg" alt="" v-else>
            <div class="company-simple" v-if="item.type==='上市公司'">
              <p class="name">{{item.shortName}}<i v-if="item.attestationFlag==='已认证'"></i></p>
              <p class="code">{{item.stockCode}}</p>
            </div>
            <div class="company-simple item_name" v-else>
              <p class="name"><span>{{cutstr(item.name,30)}}<i v-if="item.attestationFlag==='已认证'"></i></span></p>
            </div>
          </div>
          <div class="company-name">
            <p class="complete-name" v-if="item.type==='上市公司'">{{item.name}}</p>
            <p class="synopsis-text" v-else v-html="setSynopsis(item.propertyList)"></p>
            <div class="industry-address">
              <p class="industry-text">{{item.industry}}</p>
              <p class="address-text">
                <span>{{item.address}}</span>
                <span class="collect" :class="item.attention?'iscollect':''" @click.stop="collectFn(item)"></span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <NoData v-else />
      <p class="loading-more" v-if="!finished && !loadMore && listArr.length && pageNo>=2" @click="getMore">加载更多</p>
      <p class="load-finish" v-if="finished && listArr.length && pageNo>2">加载完毕</p>
      <p class="loading-data" v-if="loadMore && listArr.length"><img src="@/assets/img/article_list/loading.gif"/>加载中...</p>
    </div>
    <div v-if="showTop&&listArr.length>8" class="top-btn" @click="goTop" ref="goTop">
      <img src="@/assets/img/calendar/top.svg" alt="">
    </div>
  </div>
</template>

<script>
import "@/assets/css/load_more.less";
import { setCatalogTree } from "@/utils/store-data";
import { getAreaList,getIndustryList,getCompanyList } from "@/api/calendar"
import { collectRold } from "@/api/rold"
import Select from '../enterprise/components/select.vue'
import NoData from '@/components/NoData'
import BuryingPont from "@/mixins/buryingPoint.js";
import CompanySearch from '@/components/companySearch'

export default {
  components: { Select, NoData, CompanySearch },
  mixins: [BuryingPont],
  data() {
    return {
      searchName: '',
      areaList: [{id:0,name:'全部'}],
      province: '全部',
      provinceFlag: false,
      industryList: [{id:0,name:'全部'}],
      industry: '全部',
      industryFlag: false,
      listArr: [],
      isEmpty: false,
      pageNo: 1,
      pageSize: 8,
      loading: false,
      finished: false,
      loadMore: false,
      loadFlag: false,
      showTop: false,
    };
  },
  computed: {
    chooseProvince(){
      if(this.province == '全部'){
        return '';
      }else{
        return this.province;
      }
    },
    chooseIndustry(){
      if(this.industry == '全部'){
        return '';
      }else{
        return this.industry;
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener('scroll', this.scrollFns)
    next()
  },
  created(){
    setCatalogTree(this.$store);
    this.getAreaList();
    this.getIndustryList();
    this.getCompanyList();
    this.loadSecond();
  },
  mounted() {
    window.addEventListener('scroll', this.scrollFns)
  },
  methods:{
    // 省份列表
    getAreaList(){
      getAreaList().then(res=>{
        var allArea = res.data.areaList.map(item=>{
          return {id:item.id,name:item.NAME}
        });
        this.areaList = this.areaList.concat(allArea)
      }).catch(()=>{})
    },
    // 选中省份数据返回
    provinceBack(data){
      this.province = data.name;
    },
    // 省份列表是否显示的返回
    provinceFlagFn(data){
      this.industryFlag = false;
      this.provinceFlag = data;
    },
    // 行业列表
    getIndustryList(){
      getIndustryList().then(res=>{
        var allIndustry = res.data.areaList.map(item=>{
          return {id:item.id,name:item.name}
        })
        this.industryList = this.industryList.concat(allIndustry)
      }).catch(()=>{})
    },
    // 行业数据返回
    industryBack(data){
      this.industry = data.name;
    },
    // 选中行业列表是否显示的返回
    industryFlagFn(data){
      this.provinceFlag = false;
      this.industryFlag = data;
    },
    // 收藏
    collectFn(item){
      if(this.$store.state.token) {
        if(this.$store.state.token.indexOf('BC_') != -1){
          // this.abnormalTip = true;
          // this.abnormalTxt = '企业用户不可收藏，请';
          // this.isLoginLink = true;
          this.$message({tipTxt:'企业用户不可收藏，请',isLink:true,loginTxt:'登录个人账户'});
        }else{
          collectRold({id:item.id,type:11}).then(res=>{
            console.log(res)
            item.attention = !item.attention;
          }).catch(()=>{})
        }
      } else {
        this.$router.push('/login')
      }
    },
    // 跳转详情
    calendarDeatil(id,stockCode){
      var detailUrl;
      if(stockCode){
        detailUrl = this.$router.resolve({path:'/enterprise/deatil',query:{id:id,columnId:this.$route.query.columnId,stockCode:stockCode}});
      }else{
        detailUrl = this.$router.resolve({path:'/enterprise/deatil',query:{id:id,columnId:this.$route.query.columnId}});
      }
      window.open(detailUrl.href,'_blank')
    },
    // 搜索企业
    searchCompany(){
      if(!this.searchName&&!this.province&&!this.industry){
        this.isEmpty = true;
      }else{
        this.finished = false;
        this.pageNo = 1;
        this.isEmpty = false;
        this.getCompanyList('search');
        this.loadSecond();
      }
    },
    // 加载更多
    getMore(){
      this.loading = true;
      this.finished = false;
      this.loadMore = true;
      this.pageNo++
      this.getCompanyList()
    },
    // 企业列表
    getCompanyList(txt){
      if(!this.loadFlag){
        this.loadFlag = true;
        var listData = {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          nameAndCode: this.searchName,
          industry: this.chooseIndustry,
          address: this.chooseProvince
        }
        getCompanyList(listData).then(res=>{
          if(res.count<=0){
            this.finished = true;
          }
          if(this.pageNo == 1) {
            this.listArr = [];
          }
          if(res.data&&res.data.length>0){
            res.data.forEach(item => item.error = false);
            this.listArr = this.listArr.concat(res.data);
            if(this.listArr.length>=res.count){
              this.finished = true;
            }
          }
          // 浏览埋点
          this.sensors.track( 'LY_view_list_page',{
            AppName: this.$store.state.AppName, 
            page_id: '', 
            total_page: res.count % this.pageSize <= 0 ? res.count / this.pageSize : parseInt(res.count / this.pageSize) + 1,
            first_level_column: '企业中心',
            number: res.count,
            is_login: false, 
            platform: this.$store.state.platform
          })
          // 搜索埋点
          if(txt&&txt=='search'){
            this.sensors.track( 'LY_search_result' ,{
              AppName: this.$store.state.AppName, 
              is_login: false, 
              has_result: res.count > 0 ? true : false,
              platform: this.$store.state.platform,
              search_keyword: this.searchName,
              search_result_num: res.count,
            })
          }
        }).catch(()=>{}).finally(()=>{
          this.loading = false;
          this.loadMore = false;
        }).finally(()=>{
          this.loadFlag = false;
        })
      }
      
    },
    // 2k屏没有滚动条时加载第二页
    loadSecond(){
      var that = this;
      var loadData = setInterval(function(){
        if(!this.finished && !that.loadFlag && that.pageNo < 2){
          that.scrollFns();
        }
        if(that.pageNo >= 2 || this.finished){
          clearInterval(loadData)
        }
      },100)
    },
    // 滚动事件
    scrollFns(){
      var scrollH = document.body.scrollHeight;
      var clientH = document.body.clientHeight;
      var toTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if(scrollH-clientH-toTop<322 && !this.loading && !this.finished && this.pageNo<2){
        this.loading = true;
        this.finished = false;
        this.loadMore = true;
        this.pageNo++
        this.getCompanyList()
      }
      if(toTop>400){
        this.showTop = true;
      }else{
        this.showTop = false;
      }
    },
    // 设置非上市公司的简介
    setSynopsis(name){
      var synopsis = '';
      if(name){
        name.forEach(item=>{
          if(item.name == '简介'){
            synopsis = item.content.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g,'').replace(/&nbsp;/ig,'');
          }
        })
      }
      
      return synopsis;
    },
    // 非上市公司名称显示固定的字符长度
    cutstr(str, len) {
      var str_length = 0;
      var str_len = 0;
      var str_cut = new String();
      str_len = str.length;
      for (var i = 0; i < str_len; i++) {
          var a = str.charAt(i);
          str_length++;
          if (escape(a).length > 4) {
              //中文字符的长度经编码之后大于4 
              str_length++;
          }
          str_cut = str_cut.concat(a);
          if (str_length >= len) {
              return str_cut;
          }
      }
      //如果给定字符串小于指定长度，则返回源字符串； 
      if (str_length < len) {
          return str;
      }
    },
    scrollAnimation(currentY, targetY) {
      // 获取当前位置方法
      // 计算需要移动的距离 
      var needScrollTop = targetY - currentY
      var _currentY = currentY
      setTimeout(() => {
        // 一次调用滑动帧数，每次调用会不一样
        var dist = Math.ceil(needScrollTop / 10)
        _currentY += dist
        window.scrollTo(_currentY, currentY)
       // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
        if (needScrollTop > 10 || needScrollTop < -10) {
          this.scrollAnimation(_currentY, targetY)
        } else {
          window.scrollTo(_currentY, targetY)
        }
       }, 1)
    },
    // 回到顶部
    goTop(){
      var currentY = document.documentElement.scrollTop || document.body.scrollTop        
      this.scrollAnimation(currentY, 0)
    },
  }
};
</script>
<style lang="less" scoped>
.calendar {
  position: relative;
  padding-top: 80px;
  .calendar-header {
    width: 100%;
    min-width: 1200px;
    height: 400px;
    background: url('~@/assets/img/calendar/company_header_bg.png') center center;
  }
  .calendar-main {
    margin: 0 auto;
    padding-bottom: 90px;
    width: 1200px;
    .calendar-search {
      margin: 22px 0 34px 0;
      font-size: 0;
      .search-box {
        position: relative;
        display: inline-block;
        .search-tip {
          margin-right: 10px;
          font-size: 14px;
          color: #141825;
        }
        .search-name {
          padding: 0 17px;
          width: 280px;
          height: 30px;
          display: inline-block;
          border: 1px solid rgba(0, 0, 0, 0.15);
          border-radius: 4px;
          line-height: 32px;
          font-size: 14px;
          color: #141825;
        }
        .empty-tip{
          position: absolute;
          top: 32px;
          left: 66px;
          font-size: 14px;
          color: #FA0202;
        }
      }
      .search-btn {
        float: right;
        padding: 0;
        border: none;
        width: 90px;
        height: 32px;
        // display: inline-block;
        border-radius: 4px;
        text-align: center;
        line-height: 32px;
        font-size: 18px;
        color: #ffffff;
        background: #005bac;
      }
    }
    .calendar-list{
      font-size: 0;
      .each-list{
        margin-bottom: 32px;
        width: 269px;
        // height: 169px;
        display: inline-block;
        vertical-align: top;
        box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.09);
        cursor: pointer;
        &:not(:nth-of-type(4n)){
          margin-right: 41px;
        }
        &:not(:nth-of-type(2n)){
          .simple-name{
            background: url('~@/assets/img/calendar/company_bg1.png');
          }
        }
        &:not(:nth-of-type(2n+1)){
          .simple-name{
            background: url('~@/assets/img/calendar/company_bg2.png');
          }
        }
        .simple-name{
          padding: 21px 0 13px 22px;
          width: 247px;
          height: 54px;
          .company-logo{
            width: 54px;
            height: 54px;
            display: inline-block;
            vertical-align: middle;
            border-radius: 50%;
            background: gray;
          }
          .company-simple{
            margin-left: 10px;
            display: inline-block;
            vertical-align: middle;
            .name{
              line-height: 26px;
              font-size: 20px;
              font-weight: bold;
              color: #ffffff;
              i{
                margin-left: 5px;
                width: 14px;
                height: 16px;
                display: inline-block;
                background: url('~@/assets/img/calendar/attestation.svg');
              }
            }
            .code{
              font-size: 20px;
              font-weight: 300;
              color: #ffffff;
            }
          }
          .item_name{
            .name{
              // position: relative;
              // display: flex;
              span{
                // display: inline;
                width: 164px;
                max-height: 52px;
                line-height: 26px;
                display: inline-block;
                font-size: 20px;
                overflow: hidden;
                // text-overflow:ellipsis;
                // display: -webkit-box;
                // -webkit-line-clamp: 2;
                // -webkit-box-orient: vertical;
                word-break:break-all;
              }
              i{
                display: inline-block;
                // position: absolute;

                // bottom: 0;
              }
            }
          }
        }
        .company-name{
          padding: 13px 20px;
          .complete-name{
            margin: 0 0 13px 0;
            height: 48px;
            font-size: 18px;
            color: #020202;
            overflow: hidden;
            text-overflow:ellipsis;
            display:-webkit-box;
            -webkit-box-orient:vertical;
            -webkit-line-clamp:2;
          }
          .synopsis-text{
            margin-bottom: 13px;
            height: 48px;
            line-height: 24px;
            font-size: 14px;
            color: #999999;
            overflow: hidden;
            text-overflow:ellipsis;
            display:-webkit-box;
            -webkit-box-orient:vertical;
            -webkit-line-clamp:2;
          }
          .industry-address{
            line-height: 19px;
            font-size: 14px;
            color: #999999;
            .industry-text{
              margin-bottom: 8px;
              height: 19px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .address-text{
              height: 19px;
              .collect{
                float: right;
                margin-top: 1px;
                width: 16px;
                height: 16px;
                cursor: pointer;
                background: url('~@/assets/img/calendar/collect.svg');
                &.iscollect{
                  background: url('~@/assets/img/calendar/collected.svg');
                }
              }
            }
          }
        }
      }
    }
  }
}
.top-btn{
  position: fixed;
  bottom: 322px;
  right: 30px;
  width: 64px;
  height: 64px;
  z-index: 300;
  cursor: pointer;
  &>img{
    width: inherit;
    height: inherit;
    display: inline-block;
  }
}
</style>
