import request from "@/utils/request";

/**
 * 获取banner
 */
export function getBannerList() {
  return request({
    url: "/f/front/banner/getAll",
    method: "get",
  });
}
/**
 * 获取路演列表Tab
 * @param {*pageNo} params 
 * @param {*pageSize} params 
 */
export function getRoldTabList() {
  return request({
    // url: "/f/cnfic/common/dict/index/get/type/data",
    url: "/f/epro/live/template/base/all",
    method: "get",
    // params: {
    //   types: 'live_type'
    // }
  });
}
/**
 * 获取路演列表
 * @param {*pageNo} params 
 * @param {*pageSize} params 
 */
export function getRoldList(data) {
  return request({
    url: "/f/epro/live/selectIndexNoTop",
    method: "post",
    data
  });
}

/**
 * 获取路演列表
 * @param {*pageNo} params 
 * @param {*pageSize} params 
 */
 export function getRoldListTop(data) {
  return request({
    url: "/f/epro/live/selectIndexAll",
    method: "post",
    data
  });
}

/**
 * 获取新首页数据
 */
 export function getIndexData() {
  return request({
    url: "/f/index/index",
    method: "post"
  });
}

// /f/index/index

/**
 * 路演详情页面详情
 * @param {*id} params 
 */
export function getDetail(params){
  return request({
    url: "/f/epro/live/getIndexLive",
    method: "get",
    params
  });
}
/**
 * 路演详情页面tab信息
 * @param {*liveId} params 
 * @param {*templateId} params 
 */
export function getDetailTabList(params){
  return request({
    url: "/f/epro/live/template/queryLiveTemplate",
    method: "get",
    params
  });
}
/**
 * 路演详情历史交流信息
 * @param {*liveId} params 
 * @param {*templateId} params 
 */
export function queryLiveItemByName(params){
  return request({
    url: "/f/epro/live/template/queryLiveItemByName",
    method: "get",
    params
  });
}
/**
 * 路演详情页面查询评论列表
 * @param {*pageNo} params 
 * @param {*pageNo} params 
 * @param {*liveId} params 
 */
export function getSelectedCommentList(params){
  return request({
    url: "/f/epro/live/comment/selectIndexComment",
    method: "get",
    params
  });
}
/**
 * 路演详情页面自我评论列表
 * @param {*pageNo} params 
 * @param {*pageNo} params 
 * @param {*liveId} params 
 */
export function getUserCommentList(params){
  return request({
    url: "/f/epro/live/comment/selectUserIndexComment",
    method: "get",
    params
  });
}
/**
 * 新增评论
 * @param {*commentPicture} params 
 * @param {*commentContent} params 
 * @param {*liveId} params 
 */
export function postInsertComment(data){
  return request({
    url: "/f/epro/live/comment/insertComment",
    method: "post",
    data
  });
}
/**
 * 删除个人评论
 * @param {*commentId} params 
 */
export function deleteUserComment(params){
  return request({
    url: "/f/epro/live/comment/deleteUserComment",
    params
  });
}
/**
 * 收藏
 * @param {*id} params 
 * @param {*type} params 10|路演 11|企业
 */
export function collectRold(params){
  return request({
    url: "/f/cnfic/xhcj/personalCenter/attention",
    params
  });
}
/**
 * 获取中国金融信息网直播列表接口
 * @param {*} data 
 * @returns 
 */
 export function liveList(data){
  return request({
    url: "/live/list",
    data
  });
}

/**
 * 获取直播详情信息
 */
 export function getLiveInfo(data){
  return request({
    url: "/v300/h5/live/getInfo.json",
    data
  });
}

/**
 * 获取直播信息接口
 */
export function getLiveDetail(params){
  return request({
    url: "/f/epro/live/detail",
    params
  });
}

/**
 * 直播形式列表
 */
export function roadshowType(params){
  return request({
    url: "/f/cnfic/common/dict/index/get/type/data",
    params
  });
}