import request from "@/utils/request";

/**
 * 省份列表
 */
export function getAreaList(params) {
  return request({
    url: '/f/front/businessCenter/area',
    params
  })
}
/**
 * 行业列表
 */
 export function getIndustryList(params) {
  return request({
    url: '/f/front/businessCenter/industry',
    params
  })
}

/**
 * 企业中心列表
 */
 export function getCompanyList(data) {
  return request({
    url: '/f/front/businessCenter/list',
    method: "post",
    data
  })
}
/**
 * 企业详情
 */
 export function getCompanyDetails(data) {
  return request({
    url: '/f/front/businessCenter/details',
    method: "post",
    data
  })
}
/**
 * 企业路演列表
 */
 export function businessLive(params) {
  return request({
    url: '/f/front/businessCenter/businessLive',
    params
  })
}