import request from "@/utils/request";
// 投资者问答查询
export function investorsSearch(data) {
  return request({
    url: "/f/front/investors/list",
    method: "post",
    data
  });
}
// 投资者提问接口
export function investorsAdd(data) {
  return request({
    url: "/f/front/investors/add",
    method: "post",
    data
  });
}
// 问答回复最多的接口
export function businessList(data) {
  return request({
    url: "/f/front/investors/businessList",
    method: "post",
    data
  });
}
// 投资者问答公司查询接口
export function companySearch(params) {
  return request({
    url: "/f/front/businessCenter/names",
    method: "get",
    params
  });
}
