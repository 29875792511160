<template>
  <div class="select-box" :style="{width:selectWidth+'px'}">
    <p class="selected-box">
      <input class="select-input" v-model="modelValue" :style="{width:(selectWidth-50)+'px'}" type="text" @click.stop="showList" readonly />
      <span class="down-icon" :class="listFlag?'selected-icon':''"></span>
    </p>
    <ul class="list-data" v-show="listFlag">
      <li v-for="item in selectArr" :key="item.id" @click="selectedData(item)">{{item.name}}</li>
    </ul>
  </div>
</template>
<script>
export default {
  props:{
    modelValue:{
      type: String,
      default: ''
    },
    selectWidth:{
      type: Number,
      default: 0
    },
    listFlag:{
      type: Boolean,
      default: false
    },
    selectArr:{
      type: Array,
      default:function(){
        return []
      }
    }
  },
  data(){
    return{}
  },
  methods:{
    // 选择数据并隐藏下拉列表
    selectedData(data){
      this.$emit('selectBack',data)
      this.$emit('listFlagFn',false);
    },
    // 显示下拉列表
    showList(){
      if(this.listFlag){
        this.$emit('listFlagFn',false);
      }else{
        this.$emit('listFlagFn',true);
      }
    }
  },
}
</script>
<style lang="less" scoped>
.select-box {
  position: relative;
  display: inline-block;
  .selected-box {
    position: relative;
    cursor: pointer;
    .select-input {
      position: relative;
      padding: 0 31px 0 17px;
      height: 30px;
      display: inline-block;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      line-height: 32px;
      font-size: 14px;
      color: #141825;
      cursor: pointer;
      z-index: 10;
    }
    .down-icon{
      position: absolute;
      top: 12px;
      right: 18px;
      width: 13px;
      height: 7px;
      display: inline-block;
      background: url('~@/assets/img/calendar/down_arrow.png');
      z-index: 30;
      &.selected-icon{
        transform: rotate(180deg);
      }
    }
  }
  .list-data{
    position: absolute;
    left: 0;
    top: 32px;
    width: calc(100% - 2px);
    max-height: 330px;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    background: #ffffff;
    // box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.09);
    overflow-y: auto;
    li{
      padding: 6px 17px;
      // height: 32px;
      border-bottom: 1px solid #eeeeee;
      // line-height: 32px;
      font-size: 14px;
      color: #141825;
      cursor: pointer;
      &:hover{
        border-bottom: 1px solid #005bac;
        color: #ffffff;
        background: #005bac;
      }
    }
  }
}
</style>