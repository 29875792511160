<template>
  <div class="company-search-box">
    <input
      ref="searchInput"
      :value="searchName"
      class="search-name"
      type="text"
      v-bind="$attrs"
      @input="companySearchInput"
      @focus="showSearchResultList = true"
      @blur="handlerBlur"
    />
    <div v-show="showSearchResultList && searchName" class="search-result-list">
      <div v-if="companySearchLoading" class="search-result-tip">加载中...</div>
      <template v-else-if="companySearchList && companySearchList.length">
        <div
          v-for="(item, index) in companySearchList"
          :key="index"
          v-html="companyName(item)"
          class="search-result-item"
          @click="handlerClick(item)"
        ></div>
      </template>
      <div v-else class="search-result-tip">无数据</div>
    </div>
  </div>
</template>

<script>
import { companySearch } from "@/api/investors";

export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showSearchResultList: false, // 显示下拉
      companySearchList: [], // 公司下拉数据
      companySearchLoading: false, // 公司下拉数据加载中
    };
  },
  computed: {
    // 搜索值
    searchName: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    // 公司名称
    companyName() {
      return (item) => {
        var name = item.name ? item.name : item.shortName;
        var fullName = "";
        if (item.stockCode) {
          // 公司名称
          fullName = (name + "（" + item.shortName + "-" + item.stockCode + "）").replace(/\s+/g, "");
        } else {
          // 公司名称
          fullName = name.replace(/\s+/g, "");
        }
        // 按关键字分割名称
        return fullName.replace(this.searchName, `<span class="keyword">${this.searchName}</span>`)
        // const fU = fullName.toUpperCase()
        // const sU = this.searchName.toUpperCase()
        // const keywordIndex = fU.indexOf(sU)
        // const fullNameArr = fullName.split('')
        // fullNameArr.splice(keywordIndex, sU.length, `<span class="keyword">${fullName.slice(keywordIndex, keywordIndex + sU.length)}</span>`)
        // return fullNameArr.join('')
      };
    },
  },
  methods: {
    // 企业搜索输入
    companySearchInput(e) {
      // 搜索名称赋值
      this.searchName = e.target.value;
      // 搜索
      this.companySearchChange(e.target.value);
    },
    // 搜索
    companySearchChange(searchName) {
      // 显示下拉
      this.showSearchResultList = true;
      // 清除输入计时器
      clearTimeout(window.timer_search_company);
      // 无值清空下拉
      if (!searchName) {
        this.companySearchList = [];
        this.companySearchLoading = false;
        this.showSearchResultList = false;
        return;
      }
      // 设置加载中
      this.companySearchLoading = true;
      // 关键字检索
      window.timer_search_company = setTimeout(() => {
        companySearch({
          pageNo: 1,
          pageSize: 20,
          name: searchName,
        })
          .then((res) => {
            this.companySearchList = res.data;
          })
          .catch(() => {
            this.companySearchList = [];
          })
          .finally(() => {
            this.companySearchLoading = false;
          });
      }, 300);
    },
    // input输入框得到焦点
    handlerFocus() {},
    // 失焦
    handlerBlur() {
      setTimeout(() => {
        this.showSearchResultList = false
      }, 200)
    },
    handlerClick(item) {
      this.$emit('itemClick', item)
      this.$refs.searchInput.focus()
    }
  },
};
</script>

<style lang="less">
.company-search-box {
  width: 280px;
  position: relative;
  display: inline-block;
  .search-name {
    width: 280px;
    padding: 0 17px;
    height: 30px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    line-height: 32px;
    font-size: 14px;
    color: #141825;
  }
  .search-result-list {
    max-height: 200px;
    min-width: 314px;
    overflow-y: auto;
    position: absolute;
    top: 35px;
    border: 1px solid #878787;
    background: #fff;
  }
  .search-result-item {
    height: 26px;
    line-height: 26px;
    padding: 0 9px;
    color: #454545;
    font-size: 13px;    
    word-break: break-all;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      background: #e2e2e2;
    }
    .keyword {
      color: #44aedd;
    }
  }
  .search-result-tip {
    height: 26px;
    line-height: 26px;
    font-size: 13px;
    padding: 0 9px;
    color: #454545;
  }
}
</style>
